export const useLogin = async (email: string, password: string, stayLoggedIn: boolean) => {
  const { accountAPI } = useBackendRequests();
  const isAuthenticated = useAuthState();
  const accountStore = useAccountStore();
  const route = useRoute();

  await accountAPI.login(email, password, stayLoggedIn);
  isAuthenticated.value = true;
  await accountStore.loadData();

  if (route.query.redirect) {
    return navigateTo(route.query.redirect as string);
  } else {
    return navigateTo('/dashboard/matches');
  }
};
