<script setup lang="ts">
import { I18nT } from 'vue-i18n';

definePageMeta({
  middleware: [
    function () {
      const isAuthenticated = useAuthState();
      if (isAuthenticated.value) {
        return navigateTo('/dashboard/matches');
      }
    },
  ],
});

const { t } = useI18n({
  useScope: 'local',
});

const { authAPI } = useBackendRequests();
const isAuthenticated = useAuthState();
const staticLinks = useStaticLinks();
const { showToast } = useToast();
const route = useRoute();
const showEmailNotConfirmedAlert = ref(false);
const showWrongCredentialsAlert = ref(false);
const errorsLogin = ref(null as unknown);
const isLoggingIn = ref(false);
const redirect = route.query.redirect as string || undefined;

const { data: ssoProviders } = useAsyncData('fetch-sso-providers-for-login', () => authAPI.getSSOProviders('login', redirect), { lazy: true });

onMounted(() => {
  if (route.query.notLoggedIn) {
    showToast('info', t('not-logged-in'), t('log-in-again'));
  }

  window.scrollTo({ top: 0, behavior: 'smooth' });

  const email = document.querySelector('#email') as HTMLInputElement;
  if (email) {
    email.focus();
  }
});

async function onSubmit(values: { email: string; password: string; stayLoggedIn: boolean }) {
  showEmailNotConfirmedAlert.value = false;
  showWrongCredentialsAlert.value = false;
  isLoggingIn.value = true;

  try {
    await useLogin(values.email, values.password, values.stayLoggedIn);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response?.status === 400) {
      showWrongCredentialsAlert.value = true;
    }

    if (error.response?.status === 401) {
      showEmailNotConfirmedAlert.value = true;
    }

    errorsLogin.value = error;
    isAuthenticated.value = false;
  }
}

useSeoMeta({
  description: t('meta.description'),
});
</script>

<template>
  <div>
    <article>
      <h1>{{ t('login') }}</h1>
      <tw-login-alert-wrong-credentials v-if="showWrongCredentialsAlert" />
      <tw-login-alert-email-not-confirmed v-if="showEmailNotConfirmedAlert" />

      <section>
        <tw-form-login @submit="onSubmit" />
      </section>

      <section class="forgot-password-section">
        <div class="link-action">
          <tw-ui-action is="link" variant="text-primary" class="login-links" to="/account/forgot-password">
            {{ t('forgot-password') }}
          </tw-ui-action>
        </div>
      </section>
      <template v-if="ssoProviders?.urls && !Array.isArray(ssoProviders?.urls)">
        <div class="divider">
          <hr />
          <span>{{ t('or') }}</span>
          <hr />
        </div>
        <section>
          <tw-sso-buttons :sso-urls="ssoProviders?.urls" type="login" />
        </section>
      </template>
      <div class="divider">
        <hr />
      </div>
      <section class="register-section">
        <h2>{{ t('i-am-new-here') }}</h2>
        <div class="link-action">
          <tw-ui-action is="link" variant="button-outlined" to="/registrieren" class="buttons">
            {{ t('button-register-now') }}
          </tw-ui-action>
        </div>
      </section>
      <hr />
      <section>
        <I18nT keypath="notes-on-logging-in-with-sso" tag="p">
          <template v-slot:agb>
            <tw-ui-action is="link" variant="text-primary" class="login-links" :to="staticLinks.agb">{{
              t('terms') }}</tw-ui-action>
          </template>
          <template v-slot:privacy>
            <tw-ui-action is="link" variant="text-primary" class="login-links" to="/datenschutz">
              {{ t('privacy') }}
            </tw-ui-action>
          </template>
        </I18nT>
      </section>
    </article>
  </div>
</template>

<style scoped lang="scss">
@import '~/assets/styles/tw-variables.scss';
@import '~/assets/styles/tw-mixins.scss';
@import '~/assets/styles/tw-form.scss';

article {
  padding: 0 1rem;
  margin: 0 auto;
  max-width: 600px;
}

h1,
h2 {
  font-size: 1.5rem;
  margin: 1.5rem 0 1rem;
  text-transform: uppercase;
  @include tw-is-desktop {
    font-size: 2rem;
  }
}

.forgot-password-section {
  margin-bottom: 1rem;
}

.register-section {
  margin-bottom: 2rem;
}

.link-action {
  display: flex;
  text-align: center;
  padding-top: 0.5rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.login-links {
  font-weight: normal;
}

.buttons {
  width: 100%;
  max-width: 400px;
}

.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;

  p {
    margin: 0;
  }
}

.divider {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;

  span {
    text-transform: uppercase;
    padding: 0 30px;
    color: #212529;
    font-size: 1.25rem;
  }

  hr {
    color: #b8b7b7;
    border: 1px solid #b8b7b7;
    width: 100%;
  }
}
</style>

<i18n lang="json">
{
  "de": {
    "login": "Anmelden",
    "forgot-password": "Passwort vergessen?",
    "or": "oder",
    "i-am-new-here": "Neu bei Tauschwohnung?",
    "button-register-now": "Jetzt registrieren",
    "meta": {
      "description": "Melde dich hier in deinem Konto auf tauschwohnung.com an. Du hast noch keins? Einfach über /registrieren ein neues Konto erstellen."
    },
    "notes-on-logging-in-with-sso": "Wenn du auf \"Mit Facebook anmelden\", \"Mit Google anmelden\" oder \"Mit Apple anmelden\" klickst, stimmst du den {agb} und {privacy} zu.",
    "terms": "AGB",
    "privacy": "Datenschutzbestimmungen"
  },
  "en": {
    "login": "Login",
    "forgot-password": "Forgot password?",
    "or": "or",
    "i-am-new-here": "New at Tauschwohnung?",
    "button-register-now": "Register now",
    "meta": {
      "description": "Log in to your account on tauschwohnung.com here. Don't have one yet? Simply create a new account via /registrieren."
    },
    "notes-on-logging-in-with-sso": "If you click on \"Login with Facebook\", \"Login with Google\" or \"Login with Apple\", you agree to the {agb} and {privacy}.",
    "terms": "terms",
    "privacy": "privacy policy"
  }
}
</i18n>
